@import './variable.scss';

.home-hero {
    width: 100%;
    // height: 100vh;
    position: relative;
    overflow: hidden;
    // display: flex;

    .hero-slide{
        position: relative;
        width: 100%;
        height: 100vh;
    }

    .hero-image {
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: -1;
        &::after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(2,14,18,0.5);
        }
    }

    .bg-img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        z-index: -1;
    }

    .hero-text {
        display: flex;
        height: 100%;
        flex-direction: column;
        justify-content:end;
    }

    .hero-text {
        padding-left: 60px;
        // margin-bottom: 90px;
        .hero-title {
            font-size: 75px;
            line-height: 1.1;
            color: $bg;
            font-weight: 400;
            margin-bottom: 20px;
            opacity: 1;
            transition: all 0.5s ease;

        }

        

        .hero-para{
            color: $bg;
            font-size: 18px;
            line-height: 1.3;
            width: 45%;
            font-weight: 400;
            padding-bottom: 30px;
            opacity: 1;
        }

    
        .cta-btn {
            padding-bottom: 30px;
            opacity: 1;
        }

        .appear {
            opacity: 1;
            transition: all 0.5s ease;
        }
    }
}



@media screen and (max-width: 768px){
    .home-hero {
        .hero-text {
            padding-left: 25px;
            padding-right: 25px; // Reset padding for smaller screens
        }
    }

    .home-hero .hero-text .hero-title {
        font-size: 42px;
        margin-bottom: 20px;
    }

    .home-hero .hero-text .hero-para{
        width: 100%;
        font-size: 16px;
    }
}

@media screen and (max-width: 992px){

}