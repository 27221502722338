@import './variable.scss';

.blog-content {
    background-color: $bg;
    img {
        width: 100%;
    }

    &-wrapper {
        padding: 60px 0;
        h2 {
            font-size: 16px;
            color: $dark;
        }
        h3 {
            color: $accent;
            font-size: 14px;
        }
    }

    &-main {
        p{
        padding-bottom: 30px;
        font-size: 18px;
        line-height: 1.4;
        color: $dark;
        }
    }

    .para-blog{
        margin-bottom: 25px;
        font-size: 20px;
        text-align: justify;
    }

    &-terms {
        .social-icons{
            display: flex;

            .social-icon {
                // border-radius: 50%;
                // border: 1px solid $dark;
                // padding: 7px;
                margin-right: 10px;

                svg {
                    width: 22px;
                    height: 22px;
                }
            }
        }
    }
}