@import './variable.scss';

.header{
    width: 100%;
    position: fixed;
    top: 0px;
    left: 0;
    z-index: 999;
    padding: 20px 0;
    background-color: $dark;
    border-bottom: 1px solid rgba(242,242,242,0.3);
}

.quote-close-btn {
    position: absolute;
    right: 30px;
    top: 30px;
    span {
        display: block;
        width: 35px;
        border-radius: 1px;
        background-color: $accent;
        height: 3px;

        &:first-child {
            transform: translate(-50%, -50%) rotateZ(45deg);
            transform-origin: center;
        }

        &:last-child {
            transform: translate(-50%, -50%) rotateZ(-45deg);
            transform-origin: center;

        }
    }
}

    // position: absolute;
    // left: 0;
    // top: 0;
    // width: 100%;

.quote-overlay{
    width: 100%;
    height: 100vh;
    position: absolute;
    left: 0;
    top: 0;
    background-color: rgba(0,0,0,0.5);
    display: none;
}

.quote-main {
    background-color: #fff;
    width: 30%;
    position: fixed;
    right: -100%;
    top: 0;
    height: 100%;
    z-index: 500;

    .input-row {
        width: 100%;
        display: flex;
        flex-direction: column;
        margin-bottom: 15px;

        input {
            padding: 8px;
            color: $dark;
        }

        label {
            font-size: 18px;
            margin-bottom: 5px;
            color: $dark;
            
        }
    }

    .checkboxes {
        display: flex;
        flex-wrap: wrap;
        label {
            margin: 5px;
            border: 1px solid black;
            border-radius: 30px;
            // padding: 20px;
            height: 40px;
            display: flex;
            width: fit-content;
            min-width: 160px;
            // padding: 0 15px;
            align-items: center;
            span {
                text-align: center;
                border-radius: 30px;
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 0 15px;

            }
            input {
                position: absolute;
                display: none;
                &:checked + span {
                    background-color: $dark;
                    color: $white;
                }
            }
        }
        span {
            display: block;
        }
    }

    .quote-form {
        .submit-btn {
            margin-top: 30px;
            background-color: $accent;
            border: 1px solid $accent;
            color: $white;
            &:hover {
                background-color: $dark;
                color: $white;
                border-color: $white;
            }
        }
    }
}

.quote-form {
    padding: 30px;
}


.view{
    top: 0px;
    transition: all 0.2s ease
}

.header.fixed {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
}



.mega-menu {
    position: absolute;
    width: 100%;
    max-height: 800px;
    background-color: #f2f2f2;
    z-index: 1;
    transform: translate(-50%, 0);
    top: 100px;
    border-radius: 5px;
    opacity: 0;
    left: 50%;
    height: 0px;
    overflow: hidden;
    padding: 40px 60px;
}

.mega-menu-content h4 {
    font-size: 28px;
    color: $dark;
    margin-bottom: 20px;
    pointer-events: none;
}

.mega-menu-content p {
    color: $dark;
    padding: 7px 0;
    opacity: 1;
    transition: all 0.2s;


    &:hover {
        padding-left: 10px;
        transition: all 0.2s;
        color: $accent;
    }
}

.navigation {
    width: 100%;
    display: flex;
    flex-direction: row;

    .logo {
        width: 150px;
        // margin-right: 45px;
        img {
            width: 100%;
        }
    }

    .menu {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-left: 80px;

        &-cta {
            display: flex;
            align-items: center;
            h6 {
                color: $accent;
                margin-bottom: 0px;
                margin-right: 15px;
                font-size: 18px;
            }

        }
        a {
            text-decoration: none;
        }
    }
    
    .menu-items {
        ul {
            display: flex;
            list-style: none;
            align-items: center;
            padding: 0px !important;
            margin: 0px !important;
            
            li {
                margin-right: 60px;

                a {
                    text-decoration: none;
                    color: $white;
                }
                p{
                    font-size: 18px;
                    color: $white;
                    // letter-spacing: 1px;
                }
            }
        }
    }

    .menu-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
    }

    .contact-btn {
        background-color: $bg;
        border-radius: 30px;
        padding: 15px 40px;

        a {
            color: $dark;
        }
        p {
            font-size: 18px;
            color: $dark
        }
    }
}

.mobile-navigation {
    display: none;
}

.services-item {
    cursor: pointer;
}
.hamburger {
    display: none;
    svg {
        fill: $white;
        font-size: 32px;
    }
}

@media screen and (max-width: 768px) {
    .quote-main {
        width: 100%;
    }

    .quote-main .checkboxes label {
        width: 120px;
    }

    .quote-main .input-row label {
        font-size: 14px;
    }

    .mobile-menu-wrapper .mobile-menu .mobile-items p {
        font-size: 22px!important;
    }
    .mobile-menu-wrapper .mobile-menu .mobile-contact p{
        font-size: 16px;
        padding-bottom: 15px;
    }
    .hamburger {
        display: block;
    }
    .navigation .menu {
        text-align: right;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        // align-items: flex-end;
    }
    .menu-items, .contact-btn{
        display: none;
    }
    .mobile-navigation{
        width: 100%;
        height: 100vh;
        background-color: $bg;
        position: fixed;
        left: -100%;
        top: 0;
        display: block;
        overflow: hidden;
    }

    .mobile-menu-wrapper {
        padding: 25px 30px;
        width: 100%;
        height: 100%;
        .mobile-menu {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            // align-items: flex-end;
            .mobile-items {
                p{
                    color: $dark;
                    font-size: 32px;
                    font-weight: 500;
                }
            }

            .mobile-contact {
                p{
                    span{
                        color: $accent;
                        font-size: 16px;
                        font-weight: 600;
                    }

                    font-size: 16px;
                    // font-weight: 600;
                    color: $dark;
                }
            }
        }

        .close {
            position: absolute;
            top: 30px;
            right: 30px;

            svg {
                font-size: 32px;
                fill: $accent;
            }
        }
    }
}

@media screen and (max-width: 1200px) {
    .quote-main {
        width: 70%;
    }

    .quote-main .checkboxes label {
        width: 120px;
    }

    .quote-main .input-row label {
        font-size: 14px;
    }
    .hamburger {
        display: block;
    }
    .navigation .menu {
        text-align: right;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        // align-items: flex-end;
    }
    .menu-items, .contact-btn{
        display: none;
    }
    .mobile-navigation{
        width: 100%;
        height: 100vh;
        background-color: $bg;
        position: fixed;
        left: -100%;
        top: 0;
        display: block;
    }

    .mobile-menu-wrapper {
        padding: 25px 30px;
        width: 100%;
        height: 100%;
        .mobile-menu {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            // align-items: flex-end;
            .mobile-items {
                p{
                    color: $dark;
                    font-size: 32px;
                    font-weight: 500;
                }
            }

            .mobile-contact {
                p{
                    span{
                        color: $accent;
                        font-size: 18px;
                        font-weight: 600;
                    }

                    font-size: 18px;
                    // font-weight: 600;
                    color: $dark;
                }
            }
        }

        .close {
            position: absolute;
            top: 30px;
            right: 30px;

            svg {
                font-size: 32px;
                fill: $accent;
            }
        }
    }
}