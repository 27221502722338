@import './variable.scss';

.custom-button {
    // min-width: 200px;
    width: fit-content;
    padding: 20px 45px 20px 45px;
    background-color: $bg;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    position: relative;
    overflow: hidden;
    // width: 250px;
    // height: 55px;

    &::after {
        content: '';
        background-image: url('../assets/arrow.png');
        background-repeat: no-repeat;
        background-position: 97% 50%;
        position: absolute;
        width: 100%;
        height: 100%;
        border-radius: 50px;
        background-color: $accent;
        left: -100%;
        bottom: 0px;
        // transform: translateX(0%);
        z-index: 0;
        transition: all 0.2s cubic-bezier(0.215, 0.610, 0.355, 1);
    }

    &:hover {
        // padding-left: 25px;
        &::after {
            bottom: 0%;
            left: 0;
            transition: all 0.2s cubic-bezier(0.215, 0.610, 0.355, 1);
        }

        p {
            color: #fff;
            transition: all 0.3s cubic-bezier(0.215, 0.610, 0.355, 1);
        }
    }

   
    // margin-right: 10px;
    p{
        color: $dark;
        font-size: 16px;
        font-weight: 500;
        text-transform: capitalize;
        z-index: 5;
        position: relative;
        // line-height: 3;
    }

    .custom-button-arrow {
        margin-left: 15px;
        z-index: 5;
        position: absolute;
        width: 45px;
        height: 45px;
        padding: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        background-color: $accent;
        // opacity: 0;
        left: 5px;
        .arrow {
            width: 75%;
            margin: auto;
            object-fit: cover;
        }
    }
}

@media screen and (max-width: 768px) {
    
}