@import './variable.scss';

.blogs-list {
    padding: 0px 0;
    background-color: $bg;
}

.blogcard-margin{
    margin-bottom: 60px;
}

.blogs-list-wrapper-main {
    padding: 90px 0 0 0;
    background-color: $bg;
}

.blogs-list .row {
    margin-bottom: 60px;
}

