@import './variable.scss';

.blog-hero{
    width: 100%;
    background-color: $bg;
    // background-color: $dark;
    padding: 80px 0 0 0 ;
    // height: 60vh;
}

.blog-tags{
    display: flex;
    padding-bottom: 30px;
    h4 {
        font-size: 12px;
        margin-bottom: 0px;
        text-transform: uppercase;
    }

    .blog-tag {
        border: 1px solid #111;
        border-radius: 30px;
        padding: 10px 30px;
        margin-right: 15px;
    }
}

.blog-inner {
    min-height: 55vh;
    width: 100%;
    display: flex;
    justify-content: space-between;
    // align-items:end;
    align-items: center;
   
    .blog-date{
        // height: 100%;
    }
    .blog-date {
        max-width: 30%;
        p{
            width: 100%;
        }
    }
    &-title {
        max-width: 70%;
        p{
            color: rgba(0,0,0, 0.7);
            font-size: 16px;
            font-weight: 500;
            // align-self: end;
            padding-bottom: 10px;
            // padding-top: 80px;
            // max-width: 30%;
        }

        h2 {
            max-width: 100%;
            font-size: 46px;
            font-weight: 400;
        }
    }
}