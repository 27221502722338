* {
  padding: 0;
  margin: 0;
}

p, span {
  margin-bottom: 0rem!important;
}

a {
  text-decoration: none!important;
}

/* html {
  font-size: calc(100vw/1920 * 10);
} */

@font-face {
  font-family: 'Neu';
  src: url('./assets/NeueMontreal-Regular.otf') format('opentype');
  font-weight: 400;
}

@font-face {
  font-family: 'Neu';
  src: url('./assets/NeueMontreal-Medium.otf') format('opentype');
  font-weight: 500;
}

@font-face {
  font-family: 'Neu';
  src: url('./assets/NeueMontreal-Bold.otf') format('opentype');
  font-weight: 600;
}




/* @font-face {
  font-family: 'Helv';
  src: url('./assets/_H_HelveticaNeueInterface_0.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Helv';
  src: url('./assets//_H_HelveticaNeueInterface_2.ttf') format('truetype');
  font-weight: medium;
  font-style: normal;
} */

body {
  font-family: 'Neu' , serif!important;
  overflow-x: hidden;
}

.slide-in {
  position: fixed;
  top: 0;
  left: 0;
  width:100%;
  height: 100vh;
  background: #0f0f0f;
  transform-origin: right; 
}

.page-tem{
  transform-origin: center center;
  width: 100%;
  height: auto;
  position: absolute;
  overflow: hidden;
}

.slide-out {
  position: fixed;
  top: 0;
  left: 0;
  width:100%;
  height: 100vh;
  background: #0f0f0f;
  transform-origin: left; 
}

.overlay-1 {
  position: fixed;
  width: 100%;
  height: 100vh;
  left: 0;
  top: 0;
  z-index: 15151551;
  background-color: #020E12;
  /* transform-origin: top; */

  display: flex;
  justify-content: center;
  align-items: center;
}

.trans-logo {
  width: 200px;
}
.trans-logo img {
  width: 100%;
}
.overlay-2 {
  position: fixed;
  width: 100%;
  height: 100vh;
  left: 0;
  top: 0;
  z-index: 15151551;
  background-color: #000;
  transform-origin: bottom;
}
.whatsapp_float {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 40px;
  right: 40px;
  background-color: #25d366;
  color: #FFF;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  box-shadow: 2px 2px 3px #999;
  z-index: 100;
}

.whatsapp-icon {
  margin-top: 16px;
}

/* for mobile */
@media screen and (max-width: 767px) {
  .whatsapp-icon {
      margin-top: 10px;
  }

  .whatsapp_float {
      width: 40px;
      height: 40px;
      bottom: 20px;
      right: 10px;
      font-size: 22px;
  }
}
/* html {
  font-size: calc(100vw/1920 * 10);
} */