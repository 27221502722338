@import './variable.scss';
.nc {
    background-color: $bg;
    padding: 0 0 60px 0;
    &-para {
        padding: 30px 0px;
    }

    &-points {
        margin-bottom: 15px;
    }
}